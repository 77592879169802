var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.loading
        ? _c(
            "div",
            { staticClass: "py-2 animated fadeIn" },
            [
              _c("title-muted", {
                attrs: { title: _vm.$t("drivers.wallet.bankData") },
              }),
              _vm.account
                ? _c("div", [
                    _c("p", { staticClass: "d-flex justify-content-between" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v(_vm._s(_vm.$t("drivers.wallet.bankData")) + ":"),
                      ]),
                      _vm._v(" " + _vm._s(_vm.account.bank_name) + " "),
                    ]),
                    _c("p", { staticClass: "d-flex justify-content-between" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v(
                          _vm._s(_vm.$t("drivers.wallet.branchName")) + ":"
                        ),
                      ]),
                      _vm._v(" " + _vm._s(_vm.account.branch) + " "),
                    ]),
                    _c("p", { staticClass: "d-flex justify-content-between" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v(
                          _vm._s(_vm.$t("drivers.wallet.accountNumber")) + ":"
                        ),
                      ]),
                      _vm._v(" " + _vm._s(_vm.account.number) + " "),
                    ]),
                    _c("p", { staticClass: "d-flex justify-content-between" }, [
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v(
                          _vm._s(_vm.$t("drivers.wallet.accountType")) + ":"
                        ),
                      ]),
                      _vm._v(
                        " " +
                          _vm._s(_vm.setTypeAccount(_vm.account.account_type)) +
                          " "
                      ),
                    ]),
                  ])
                : _c("div", { staticClass: "text-center" }, [
                    _c("p", [
                      _vm._v(
                        _vm._s(_vm.$t("drivers.wallet.withoutAccount")) + "."
                      ),
                    ]),
                  ]),
              _c(
                "b-btn",
                {
                  staticClass: "p-0 mb-3 mt-1",
                  attrs: {
                    type: "button",
                    variant: "outline-primary",
                    block: "",
                  },
                  on: { click: _vm.showModal },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("payments.labels.updateBankData")) + " "
                  ),
                ]
              ),
              _c("modal-bank-data", {
                attrs: { profile: _vm.profile },
                on: { refresh: _vm.getDefaultBankAccount },
                model: {
                  value: _vm.showModalBankData,
                  callback: function ($$v) {
                    _vm.showModalBankData = $$v
                  },
                  expression: "showModalBankData",
                },
              }),
            ],
            1
          )
        : _c("loader"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }