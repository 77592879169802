<template>
  <div>
    <div
      v-if="!loading"
      class="py-2 animated fadeIn"
    >
      <title-muted :title="$t('drivers.wallet.bankData')" />
      <div v-if="account">
        <p class="d-flex justify-content-between">
          <span class="text-muted">{{ $t('drivers.wallet.bankData') }}:</span> {{ account.bank_name }}
        </p>
        <p class="d-flex justify-content-between">
          <span class="text-muted">{{ $t('drivers.wallet.branchName') }}:</span> {{ account.branch }}
        </p>
        <p class="d-flex justify-content-between">
          <span class="text-muted">{{ $t('drivers.wallet.accountNumber') }}:</span> {{ account.number }}
        </p>
        <p class="d-flex justify-content-between">
          <span class="text-muted">{{ $t('drivers.wallet.accountType') }}:</span> {{ setTypeAccount(account.account_type) }}
        </p>
      </div>

      <div
        v-else
        class="text-center"
      >
        <p>{{ $t('drivers.wallet.withoutAccount') }}.</p>
      </div>

      <b-btn
        type="button" 
        variant="outline-primary"
        block
        class="p-0 mb-3 mt-1"
        @click="showModal"
      >
        {{ $t('payments.labels.updateBankData') }}
      </b-btn>
          
      <modal-bank-data
        v-model="showModalBankData"
        :profile="profile"
        @refresh="getDefaultBankAccount"
      />
    </div>
    <loader v-else />
  </div>
</template>

<script>
import TitleMuted from '@components/shared/title-muted.vue'
import GET_DEFAULT_BANK_ACCOUNT from '@graphql/payment/queries/getDefaultBankAccount.gql';
import { BANK_ACCOUNT_TYPE_LIST } from '@enums/bankAccountType';
import { makeQuery } from '@graphql/middleware'
import Loader from '@components/shared/loader.vue'
export default {
  name: 'DriverPaymentMethods',
  components: {
    TitleMuted,
    Loader,
    ModalBankData: () => import('@components/modals/driver-bank-data')
  },
  props: {
    profile: {
      type: Object,
      required: false,
      default: () => {}
    },
    driverId: {
      type: String,
      require: true,
      default: () => ''
    }
  },
  data () {
    return {
      showModalBankData: false,
      account: null,
      loading: true,
      bankAcountType: BANK_ACCOUNT_TYPE_LIST
    }
  },
  mounted () {
    this.getDefaultBankAccount()
  },
  methods: {
    getDefaultBankAccount () {
      this.loading = true
      makeQuery(
        GET_DEFAULT_BANK_ACCOUNT,
        { 
          driver: this.driverId
        }
      )
      .then((res) => {
        this.account = res.data.getDefaultBankAccount
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        this.loading = false
        this.$emit('account', this.account)
      })
    },
    setTypeAccount(type) {
      const accountType = this.bankAcountType.find((account) => account.value === type)
      return accountType.text
    },
    showModal () {
      this.showModalBankData = true
    }
  }
}
</script>

<style scoped>
.btn-border-left {
  padding-left: 0px !important;
}
.div-item-payment {
  cursor: pointer;
}
.text-toast-custom {
  font-size: 12px;
}
</style>